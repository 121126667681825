import React, { lazy, Suspense } from "react";
import ProtectedRoutes from "./ProtectedRoutes";
import { ThemeProvider } from "./context/Themes";
import { LoaderProvider } from "./context/Preloader";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";


/// GLOBAL ROUTES
const Login = lazy(() => import("./pages/master/Auth/Login"));
const DeleteRequesta = lazy(() =>
  import("./pages/master/ExternalPage/DeleteRequest")
);
const BroadcastMessage = lazy(() =>
  import("./pages/master/BroadcastMsg/BroadcastMsg")
);
const PrivacyPolicy = lazy(() =>
  import("./pages/master/ExternalPage/PrivacyPolicy")
);
const RefundPolicy = lazy(() =>
  import("./pages/master/ExternalPage/RefundPolicy")
);
const ContactForm = lazy(() =>
  import("./pages/master/ExternalPage/ContactForm")
);
const Error = lazy(() => import("./pages/supports/Error"));

///PROTECTED ROUTES
const Dashboard = lazy(() => import("./pages/master/Dashboard/Dashboard"));

const TicketHistory = lazy(() =>
  import("./pages/master/Tickets/TicketHistory")
);
const ScanTicketHistory = lazy(() =>
  import("./pages/master/Tickets/ScannedTickets")
);
const TicketDetails = lazy(() =>
  import("./pages/master/Tickets/TicketDetails")
);

const Events = lazy(() => import("./pages/master/Events/Events"));
const AddEvents = lazy(() => import("./pages/master/Events/AddEvents"));
const UpdateEvent = lazy(() => import("./pages/master/Events/UpdateEvent"));
const PreviewEvents = lazy(() => import("./pages/master/Events/PreviewEvents"));

const Parking = lazy(() => import("./pages/master/Parking/Parking"));
const ParkingSlotDetails = lazy(() =>
  import("./pages/master/Parking/ParkingSlotDetails")
);
const AddParking = lazy(() => import("./pages/master/Parking/AddParking"));
const UpdateParking = lazy(() =>
  import("./pages/master/Parking/UpdateParking")
);
const Banner = lazy(() => import("./pages/master/Banner/Banner"));
const AddBanner = lazy(() => import("./pages/master/Banner/AddBanner"));
const ViewBanner = lazy(() => import("./pages/master/Banner/ViewBanner"));
const UpdateBanner = lazy(() => import("./pages/master/Banner/UpdateBanner"));

const User = lazy(() => import("./pages/master/user/Users"));
const UserProfile = lazy(() => import("./pages/master/user/userProfile"));

const Team = lazy(() => import("./pages/master/team/Team"));
const UpdateAdmin = lazy(() => import("./pages/master/team/UpdateAdmin"));
const AddAdmin = lazy(() => import("./pages/master/team/AddAdmin"));
const AdminProfile = lazy(() => import("./pages/master/team/AdminProfile"));

const ForgotPassword = lazy(() => import("./pages/master/ExternalPage/ForgotPassword"));
const MyAccount = lazy(() => import("./pages/master/Auth/MyAccount"));
const ResetPassword = lazy(() => import("./pages/master/Auth/ResetPassword"));

// const Settings = lazy(() => import("./pages/master/Settings"));
// const Permission = lazy(() => import("./pages/master/permission/Permission"));

export default function App() {


  return (
    <ThemeProvider>
      <LoaderProvider>
              <BrowserRouter>
                <Routes>
                  {/* //////////////////UNPROTECTED ROUTES////////////////////  */}

                  <Route
                    path="/login"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <Login />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/forgot-password"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ForgotPassword />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/*"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <Error />
                      </Suspense>
                    }
                  />

                  <Route
                    path="/delete-request"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <DeleteRequesta />
                      </Suspense>
                    }
                  />

                  <Route
                    path="/privacy-policy"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <PrivacyPolicy />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/refund-policy"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <RefundPolicy />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/contact-us"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ContactForm />
                      </Suspense>
                    }
                  />

                  {/* //////////////////PROTECTED ROUTES////////////////////  */}
                 
                    <Route
                      path="/"
                      element={<Navigate to="/home" replace />}
                    />

                  <Route
                    path="/home"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <Dashboard />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/ticket-history"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <TicketHistory />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/scanned-ticket-history"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <ScanTicketHistory />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  />

                  <Route
                    path="/ticket-details"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <TicketDetails />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  />

                  <Route
                    path="/events"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <Events />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  />
                  {/* <Route
                    path="/ongoing-events"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <OngoingEvents />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/expired-events"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <ExpiredEvents />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  /> */}
                  <Route
                    path="/add-event"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        {/* <ProtectedLogIn> */}
                        <AddEvents />
                        {/* </ProtectedLogIn> */}
                      </Suspense>
                    }
                  />
                  <Route
                    path="/update-event"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <UpdateEvent />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/view-event"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <PreviewEvents />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/parking"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <Parking />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/add-parking"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        {/* <ProtectedLogIn> */}
                        <AddParking />
                        {/* </ProtectedLogIn> */}
                      </Suspense>
                    }
                  />
                  <Route
                    path="/update-parking"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <UpdateParking />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/parkingSlot-details"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        {/* <ProtectedLogIn> */}
                        <ParkingSlotDetails />
                        {/* </ProtectedLogIn> */}
                      </Suspense>
                    }
                  />
                  {/* <Route
                    path="/assigned-parking"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <AssignedParking />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/unassigned-parking"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <UnassignedParking />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  /> */}

                  <Route
                    path="/banner"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <Banner />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/add-banner"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <AddBanner />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/view-banner"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <ViewBanner />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/update-banner"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <UpdateBanner />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/user"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <User />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/user-profile"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <UserProfile />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  />

                  <Route
                    path="/team"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <Team />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/add-team"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        {/* <ProtectedLogIn> */}
                        <AddAdmin />
                        {/* </ProtectedLogIn> */}
                      </Suspense>
                    }
                  />
                  <Route
                    path="/update-team"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        {/* <ProtectedLogIn> */}
                        <UpdateAdmin />
                        {/* </ProtectedLogIn> */}
                      </Suspense>
                    }
                  />
                  <Route
                    path="/team-profile"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <AdminProfile />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  />

                  <Route
                    path="/broadcast-msg"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <BroadcastMessage />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  />

                  <Route
                    path="/my-account"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <MyAccount />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/reset-password"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <ResetPassword />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  />

                  {/* <Route
                    path="/settings"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <Settings />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  /> */}

                  {/* <Route
                    path="/permission"
                    element={
                      <Suspense fallback={<LoaderProvider />}>
                        <ProtectedRoutes>
                          <Permission />
                        </ProtectedRoutes>
                      </Suspense>
                    }
                  /> */}
                </Routes>
              </BrowserRouter>
      </LoaderProvider>
    </ThemeProvider>
  );
}
